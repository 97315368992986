<template>

  <div class="text-center" v-if="isGood">

    <br><br>
    Ваши билеты по заказу <b>№{{ticket_order}}</b>

    <br><br>
    Высланы на почту
    <br>
    <b>{{ticket_email}}</b>

    <br><br>
    И их можно <a :href=ticket_url target="_blank">скачать сейчас</a>


  </div>
  <div class="text-center" v-else>
    <br><br><br><br>
    Билеты доступны в приложении
    <br>
    ГОСУСЛУГИ.КУЛЬТУРА

    <br><br>
    Грузимся

  </div>

</template>

<script>
//import store from "@/store";


export default {
  name: "TicketORD",



  data: () => ({

    isGood: false,

    isLocalAuth: false,
    tid:0,

    ticket_url    :"",
    ticket_email  :"",
    ticket_order  :0,

    // пути для театров - где живут билеты. Вообще не правильно, это надо с сервера получать
    ticketPatch:[
      "https://api.zakaz.cloud/TICKETS/ALL/",     // никто
      "https://api.zakaz.cloud/TICKETS/SAMPLO/",  // площадь
      "https://api.zakaz.cloud/TICKETS/SAMART/",  // самарт
      "https://api.zakaz.cloud/TICKETS/SMDT/",    // смдт
      "https://api.zakaz.cloud/TICKETS/PUPPET/",  // куклы
      "https://api.zakaz.cloud/TICKETS/KSC/",     // камерная
      "https://api.zakaz.cloud/TICKETS/DKTTK/",   // ДК Грань
      "https://api.zakaz.cloud/TICKETS/KOSMOS/",  // самара космическая
      "https://api.zakaz.cloud/TICKETS/DEV/",     // тестовый
      "https://api.zakaz.cloud/TICKETS/BIBLIO/"   // Библиотека НСК
    ],
  }),



  created() {

    //console.log(this.$route.params);

    if (this.$route.params.tid) {

      //console.log(this.$route.params)

      //входим под учеткой в этот театр
      // const username = "api_user@zakaz.cloud";
      // const password = "EubMWqYVyT3W3QFFo6b3JVQCk4wLmwo";
      // const isAdmin = false;
      // const theater_select = this.$route.params.tid;
      //
      // //console.log('before');
      //
      // store.dispatch(AUTH_REQUEST, { username, password, theater_select, isAdmin }).then(() => {
      //   //this.$router.push("/buy/12");
      //
      //   //console.log('ttttt');
      //
      //   this.tid = parseInt(this.$route.params.tid);
      //
      //   // открываем страницу спектакля
      //   if (this.$route.params.smartlink){
      //
      //     this.isLocalAuth = true;
      //
      //     //console.log('smart:', this.$route.params.smartlink);
      //     this.loadSmartLik(this.$route.params.smartlink);
      //
      //   }
      //   else{
      //     //console.log('no eid goto afisha');
      //   }

      // });

      //console.log('after');

    }
    else{
      console.log('нет театра нет входа')
      return '/none'
    }
  },

  methods:{



    loadSmartLik(link) {

      //console.log(link);

      let target = "smartlink";

      // для космоса проследим за банком - пока не сделали callback в сбере
      if (this.tid===7){
        target = "banklink";
      }

      // и для библиотеки
      if (this.tid===9){
        target = "banklink";
      }


      let post_data = {

        mode:{
          target: target,
          output:"pdf_file"
        },
        smartlink:link

      }

      //console.log(post_data);

      let pr2 = this.$store.state.api.findSmartLink(post_data);

      pr2.then( data =>{

        //console.log(data);

        this.ticket_url   = this.ticketPatch[this.tid]+data.file;
        this.ticket_email = data.email;
        this.ticket_order = data.payment;

        this.isGood = true;

      })

    }

  }

}
</script>

<style scoped>

</style>
